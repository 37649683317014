import React from "react";
import { Paper, Typography, Button, IconButton } from "@mui/material";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
const CardItem = ({
  title,
  description,
  image,
  handleSetSelectedCard,
  index,
  selectedIndex,
}) => {
  return (
    <>
      <Paper
        elevation={3}
        className={
          selectedIndex === index
            ? "LandingPage-SecondPartOfPage-paper-card-item-selected"
            : "LandingPage-SecondPartOfPage-paper-card-item"
        }
      >
        <Grid container>
          <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
            <Typography className="LandingPage-SecondPartOfPage-paper-card-item-title">
              {title}
            </Typography>
            <Typography className="LandingPage-SecondPartOfPage-paper-card-item-description">
              {description}
            </Typography>
          </Grid>
          <Grid
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            className="LandingPage-SecondPartOfPage-paper-card-item-button-container"
          >
            <IconButton
              className={
                selectedIndex === index
                  ? "LandingPage-SecondPartOfPage-paper-card-item-button-selected"
                  : "LandingPage-SecondPartOfPage-paper-card-item-button"
              }
              onClick={() => handleSetSelectedCard(index, image)}
            >
              <ElectricBoltIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
export default CardItem;
