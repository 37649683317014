import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography } from "@mui/material";
import CardItem from "../../components/SecondPartOfPage/CardItem";
import "./SecondPartOfPage.css";

const SecondPartOfPage = () => {
  const [selectedCard, setSelectedCard] = React.useState({
    index: 0,
    image: handleSetImagePath("/checkinout.webp"),
  });
  function handleSetSelectedCard(index, image) {
    setSelectedCard({
      index,
      image,
    });
  }
  function handleSetImagePath(imagePath) {
    return process.env.REACT_APP_GET_IMAGES + "SecondPartOfPage" + imagePath;
  }
  return (
    <section className="LandingPage-SecondPartOfPage-container">
      <Grid
        container
        spacing={2}
        className="LandingPage-SecondPartOfPage-grid-general-container"
      >
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className="LandingPage-SecondPartOfPage-title">
            Explore the solutions
          </Typography>

          <Typography className="LandingPage-SecondPartOfPage-description">
            DrayPower offers a suite of solutions to help you optimize your
            drayage operations.
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} md={5} lg={5} xl={5}>
          <Grid
            container
            direction={"column"}
            className="LandingPage-SecondPartOfPage-card-items-grid-container"
          >
            <CardItem
              title={"Efficient Yard Management"}
              description={
                " Streamline your yard operations with our comprehensive management tools. From tracking inventory to coordinating logistics, our solutions help you maintain control and improve throughput."
              }
              image={handleSetImagePath("/checkinout.webp")}
              handleSetSelectedCard={handleSetSelectedCard}
              index={0}
              selectedIndex={selectedCard.index}
            />
            <CardItem
              title={"Quick and Easy Chassis Rental"}
              description={
                "Get the chassis you need fast. DrayPower simplifies the rental process, making it quick and easy for you."
              }
              image={handleSetImagePath("/ChassisRental.webp")}
              handleSetSelectedCard={handleSetSelectedCard}
              index={1}
              selectedIndex={selectedCard.index}
            />
            <CardItem
              title={"Street-Turn Optimization"}
              description={
                "DrayPower streamlines operations by facilitating nearby container exchanges, reducing empty trips and promoting              sustainability."
              }
              image={handleSetImagePath("/SslSummary.webp")}
              handleSetSelectedCard={handleSetSelectedCard}
              index={2}
              selectedIndex={selectedCard.index}
            />
            <CardItem
              title={"Automated Invoice Management"}
              description={
                "Eliminate the hassle of manual payments and due date reminders. With DrayPower, your invoices are paid on time, every time."
              }
              image={handleSetImagePath("/InvoiceScreen.webp")}
              handleSetSelectedCard={handleSetSelectedCard}
              index={3}
              selectedIndex={selectedCard.index}
            />
          </Grid>
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={7}
          lg={7}
          xl={7}
          className="LandingPage-SecondPartOfPage-image-grid"
        >
          <section className="LandingPage-SecondPartOfPage-image-container">
            <img
              src={selectedCard.image}
              alt="Chassis Rental"
              className="LandingPage-SecondPartOfPage-image"
            />
          </section>
        </Grid>
      </Grid>
    </section>
  );
};

export default SecondPartOfPage;
