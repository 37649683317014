import FirstPartOfPage from "./containers/FirstPartOfPage/FirstPartOfPage";
import SecondPartOfPage from "./containers/SecondPartOfPage/SecondPartOfPage";
import ThirdPartOfPage from "./containers/ThirdPartOfPage/ThirdPartOfPage";
import FourthPartOfPage from "./containers/FourthPartOfPage/FourthPartOfPage";
import FifthPartOfPage from "./containers/FifthPartOfPage/FifthPartOfPage";
import Footer from "./containers/Footer/Footer";
import SocialMedia from "./containers/SocialMedia/SocialMedia";
import './root.css'
export default function Root() {
  return (
    <section className="LandingPage-RootComponent-container">
      <FirstPartOfPage />
      <SecondPartOfPage />
      <ThirdPartOfPage />
      <FourthPartOfPage />
      <FifthPartOfPage />
      <Footer />
      <SocialMedia />
    </section>
  );
}
