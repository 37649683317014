import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";

import "./FifthPartOfPage.css";

const FifthPartOfPage = () => {
  function handleSetImagePath(imagePath) {
    return process.env.REACT_APP_GET_IMAGES + "FifthPartOfPage" + imagePath;
  }

  const images1 = [
    {
      position: 1,
      name: "Random Name #1",
      image: handleSetImagePath("/1.webp"),
    },
    {
      position: 2,
      name: "Random Name #2",
      image: handleSetImagePath("/2.webp"),
    },
    {
      position: 3,
      name: "Random Name #3",
      image: handleSetImagePath("/3.webp"),
    },
    {
      position: 4,
      name: "Random Name #4",
      image: handleSetImagePath("/4.webp"),
    },
    {
      position: 5,
      name: "Random Name #5",
      image: handleSetImagePath("/5.webp"),
    },
  ];
  const images2 = [
    {
      position: 1,
      name: "Random Name #1",
      image: handleSetImagePath("/6.webp"),
    },
    {
      position: 2,
      name: "Random Name #2",
      image: handleSetImagePath("/7.webp"),
    },
    {
      position: 3,
      name: "Random Name #3",
      image: handleSetImagePath("/8.webp"),
    },
    {
      position: 4,
      name: "Random Name #4",
      image: handleSetImagePath("/9.webp"),
    },
    {
      position: 5,
      name: "Random Name #5",
      image: handleSetImagePath("/10.webp"),
    },
  ];
  return (
    <section className="LandingPage-FifthPartOfPage-container">
      <Grid
        container
        spacing={2}
        className="LandingPage-FifthPartOfPage-grid-general-container"
      >
        <Grid xs={12}>
          <Typography className="LandingPage-FifthPartOfPage-general-title">
            YMS DrayPower
          </Typography>
          <Typography className="LandingPage-FifthPartOfPage-general-description">
            YMS Draypower is a leading provider of drayage services in the
            intermodal industry. We are committed to providing the highest level
            of service to our customers.
          </Typography>
        </Grid>
        <Grid xs={12} lg={6}>
          <Carousel
            navButtonsProps={{
              className: "LandingPage-carrousel-navigation-buttons",
            }}
            showIndicators={false}
            fullHeightHover={true}
            autoPlay={true}
            swipe={true}
            duration={300}
          >
            {images1.map((item, index) => (
              <div key={index} className="LandingPage-carrousel-container">
                <img
                  src={item.image}
                  alt={item.name}
                  className="LandingPage-carrousel-image"
                />
              </div>
            ))}
          </Carousel>
        </Grid>
        <Grid xs={12} lg={6} className="LandingPage-FifthPartOfPage-grid-text">
          <Typography className="LandingPage-FifthPartOfPage-title">
            Hassle-Free Yard Services:
          </Typography>
          <Typography className="LandingPage-FifthPartOfPage-description">
            Book with us and forget the complexities of management. We handle
            everything from container management to streamlined gate processes,
            enhanced with 24/7 security, skilled personnel, and a system
            designed for peak efficiency.
          </Typography>
        </Grid>
        <Grid xs={12} lg={6}>
          <Carousel
            navButtonsProps={{
              className: "LandingPage-carrousel-navigation-buttons",
            }}
            showIndicators={false}
            fullHeightHover={true}
            autoPlay={true}
            swipe={true}
            duration={300}
          >
            {images2.map((item, index) => (
              <div key={index} className="LandingPage-carrousel-container">
                <img
                  src={item.image}
                  alt={item.name}
                  className="LandingPage-carrousel-image"
                />
              </div>
            ))}
          </Carousel>
        </Grid>
        <Grid xs={12} lg={6} className="LandingPage-FifthPartOfPage-grid-text">
          <Typography className="LandingPage-FifthPartOfPage-title">
            Strategically Located Facilities:
          </Typography>
          <Typography className="LandingPage-FifthPartOfPage-description">
            Save time with our sites positioned near the Ports, designed to
            minimize your transportation time and maximize efficiency.
          </Typography>
        </Grid>
      </Grid>
    </section>
  );
};

export default FifthPartOfPage;
