import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography, Paper } from "@mui/material";
import "./ThirdPartOfPage.css";
import Carousel from "react-material-ui-carousel";

const ThirdPartOfPage = () => {
  function handleSetImagePath(imagePath) {
    return process.env.REACT_APP_GET_IMAGES + "ThirdPartOfPage" + imagePath;
  }
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);
  const informationCarousel = [
    {
      title: "Avaliable Yards",
      description: "4",
      icon: "/StreetTurnIcon.webp",
    },
    {
      title: "Carrier Onboarding Time",
      description: "2 min",
      icon: "/StreetTurnIcon.webp",
    },
    {
      title: "Clients",
      description: "43",
      icon: "/StreetTurnIcon.webp",
    },
    {
      title: "System Uptime",
      description: "99%",
      icon: "/StreetTurnIcon.webp",
    },
  ];
  return (
    <section className="LandingPage-ThirdPartOfPage-container">
      <Grid
        container
        spacing={2}
        className="LandingPage-ThirdPartOfPage-grid-general-container"
      >
        <Grid xs={12} sm={12} md={6} lg={7} xl={7}>
          <Paper
            elevation={3}
            className="LandingPage-ThirdPartOfPage-container-video"
          >
            <div className="LandingPage-ThirdPartOfPage-video-iframe">
              <iframe
                className="LandingPage-ThirdPartOfPage-video-iframe"
                src="https://www.youtube.com/embed/rTGEBuCeu0U"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </div>
          </Paper>
        </Grid>

        <Grid
          xs={12}
          sm={12}
          md={6}
          lg={5}
          xl={5}
          className="LandingPage-ThirdPartOfPage-description-grid-container"
        >
          <Typography className="LandingPage-ThirdPartOfPage-title">
            What is DrayPower?
          </Typography>

          <Typography className="LandingPage-ThirdPartOfPage-description">
            At Draypower, we understand that managing yard logistics is
            challenging in today’s complex transportation environment. That’s
            why we’ve developed an innovative solution that goes beyond just
            software – a synergy of cutting–edge technology and human expertise
            designed to maximize your yards.
            <br />
            <br />
            Our goal is to empower your business with efficient yard management
            solutions that not only boost your operations but also provide
            significant ROI. Whether you’re looking for space, cutting-edge
            technology, or expert guidance, our offerings are designed to meet
            your unique needs.
          </Typography>
          <Grid
            container
            spacing={2}
            className="LandingPage-ThirdPartOfPage-grid-container-icon"
          >
            <Grid
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              className="LandingPage-ThirdPage-icon-container-item-grid"
            >
              <img
                src={handleSetImagePath("/Avaliable Yards.webp")}
                alt="Street Turns Icon"
                className="LandingPage-ThirdPage-icon"
              />
            </Grid>
            <Grid
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              className="LandingPage-ThirdPage-icon-container-item-grid"
            >
              <img
                src={handleSetImagePath("/Clients.webp")}
                alt="Street Turns Icon"
                className="LandingPage-ThirdPage-icon"
              />
            </Grid>
            <Grid
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              className="LandingPage-ThirdPage-icon-container-item-grid"
            >
              <img
                src={handleSetImagePath("/Time.webp")}
                alt="Tech Icon"
                className="LandingPage-ThirdPage-icon"
              />
            </Grid>
            <Grid
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              className="LandingPage-ThirdPage-icon-container-item-grid"
            >
              <img
                src={handleSetImagePath("/system Uptime.webp")}
                alt="Truck Icon"
                className="LandingPage-ThirdPage-icon"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {windowWidth > 600 ? (
        <Grid
          container
          spacing={2}
          className="LandingPage-ThirdPartOfPage-details-grid-container"
        >
          <Grid
            md={6}
            lg={3}
            xl={3}
            container
            spacing={2}
            className="LandingPage-ThirdPartOfPage-detail-background-image"
          >
            <Grid
              md={3}
              lg={3}
              xl={3}
              className="LandingPage-ThirdPartOfPage-grid-detail-icon"
            >
              <img
                src={handleSetImagePath("/StreetTurnIcon.webp")}
                alt="Truck Icon"
                className="LandingPage-ThirdPage-icon-detail"
              />
            </Grid>
            <Grid xs={9} sm={9} md={9} lg={9} xl={9}>
              <Typography className="LandingPage-ThirdPartOfPage-detail-title">
                4
              </Typography>
              <Typography className="LandingPage-ThirdPartOfPage-detail-description">
                Available Yards
              </Typography>
            </Grid>
          </Grid>
          <Grid
            md={6}
            lg={3}
            xl={3}
            container
            spacing={2}
            className="LandingPage-ThirdPartOfPage-detail-background-image"
          >
            <Grid
              md={3}
              lg={3}
              xl={3}
              className="LandingPage-ThirdPartOfPage-grid-detail-icon"
            >
              <img
                src={handleSetImagePath("/StreetTurnIcon.webp")}
                alt="Truck Icon"
                className="LandingPage-ThirdPage-icon-detail"
              />
            </Grid>
            <Grid xs={9} sm={9} md={9} lg={9} xl={9} fullWidth>
              <Typography className="LandingPage-ThirdPartOfPage-detail-title">
                2 min
              </Typography>
              <Typography className="LandingPage-ThirdPartOfPage-detail-description">
                Carrier Onboarding Time
              </Typography>
            </Grid>
          </Grid>
          <Grid
            md={6}
            lg={3}
            xl={3}
            container
            spacing={2}
            className="LandingPage-ThirdPartOfPage-detail-background-image"
          >
            <Grid
              md={3}
              lg={3}
              xl={3}
              className="LandingPage-ThirdPartOfPage-grid-detail-icon"
            >
              <img
                src={handleSetImagePath("/StreetTurnIcon.webp")}
                alt="Truck Icon"
                className="LandingPage-ThirdPage-icon-detail"
              />
            </Grid>
            <Grid xs={9} sm={9} md={9} lg={9} xl={9}>
              <Typography className="LandingPage-ThirdPartOfPage-detail-title">
                43{" "}
              </Typography>
              <Typography className="LandingPage-ThirdPartOfPage-detail-description">
                Clients
              </Typography>
            </Grid>
          </Grid>
          <Grid
            md={6}
            lg={3}
            xl={3}
            container
            spacing={2}
            className="LandingPage-ThirdPartOfPage-detail-background-image"
          >
            <Grid
              md={3}
              lg={3}
              xl={3}
              className="LandingPage-ThirdPartOfPage-grid-detail-icon"
            >
              <img
                src={handleSetImagePath("/StreetTurnIcon.webp")}
                alt="Truck Icon"
                className="LandingPage-ThirdPage-icon-detail"
              />
            </Grid>
            <Grid xs={9} sm={9} md={9} lg={9} xl={9}>
              <Typography className="LandingPage-ThirdPartOfPage-detail-title">
                99%
              </Typography>
              <Typography className="LandingPage-ThirdPartOfPage-detail-description">
                System Uptime
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Carousel
          className="LandingPage-ThirdPartOfPage-carousel"
          autoPlay={true}
          animation="slide"
          indicators={false}
          timeout={500}
          navButtonsProps={{
            className: "LandingPage-carrousel-navigation-buttons",
          }}
          navButtonsAlwaysVisible={true}
        >
          {informationCarousel.map((item, index) => (
            <Grid
              key={index}
              xs={12}
              sm={12}
              container
              spacing={2}
              className="LandingPage-ThirdPartOfPage-detail-background-image-carousel"
            >
              <Grid
                xs={3}
                sm={3}
                className="LandingPage-ThirdPartOfPage-grid-detail-icon"
              >
                <img
                  src={handleSetImagePath(item.icon)}
                  alt="Truck Icon"
                  className="LandingPage-ThirdPage-icon-detail"
                />
              </Grid>
              <Grid
                xs={9}
                sm={9}
                className="LandingPage-ThirdPartOfPage-grid-text-carousel"
              >
                <Typography className="LandingPage-ThirdPartOfPage-detail-title">
                  {item.description}
                </Typography>
                <Typography className="LandingPage-ThirdPartOfPage-detail-description">
                  {item.title}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Carousel>
      )}
    </section>
  );
};

export default ThirdPartOfPage;
