import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography, Paper } from "@mui/material";
import "./FourthPartOfPage.css";

const FourthPartOfPage = () => {
  function handleSetImagePath(imagePath) {
    return process.env.REACT_APP_GET_IMAGES + "FourthPartOfPage" + imagePath;
  }

  return (
    <section
      className="LandingPage-FourthPartOfPage-container"
      style={{
        backgroundImage: `url(${handleSetImagePath("/Background.webp")})`, // Aquí se usa la URL de la imagen de fondo
      }}
    >
      <Grid
        container
        spacing={2}
        className="LandingPage-FourthPartOfPage-grid-general-container"
      >
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={5}
          xl={5}
          className="LandingPage-FourthPartOfPage-grid-item-text"
        >
          <Typography className="LandingPage-FourthPartOfPage-title">
            DrayPower Community
          </Typography>
          <Typography className="LandingPage-FourthPartOfPage-description">
            We work as a team and as part of the community, creating groups on
            social media where we support each other with various industry
            needs.
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={7}
          xl={7}
          className="LandingPage-FourthPartOfPage-grid-item-image"
        >
          <img
            src={handleSetImagePath("/Personal.webp")}
            alt="placeholder"
            className="LandingPage-FourthPartOfPage-item-image"
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default FourthPartOfPage;
