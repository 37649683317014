import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography, Divider } from "@mui/material";
import "./Footer.css";

const Footer = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  function handleSetImagePath(imagePath) {
    return process.env.REACT_APP_GET_IMAGES + "Footer" + imagePath;
  }
  function handleMailTo(email) {
    window.location.href = `mailto:${email}`;
  }
  function handleOpenNewTab(url) {
    window.open(url, "_blank");
  }
  return (
    <section className="LandingPage-Footer-container">
      <Grid
        container
        spacing={2}
        className="LandingPage-Footer-grid-general-container"
      >
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={4}
          xl={4}
          className="LandingPage-Footer-grid-item-text"
        >
          {width < 1200 ? (
            <img
              src={handleSetImagePath("/draypowerhorizontal-svg.webp")}
              alt="DPower"
              className="LandingPage-Footer-item-image"
            />
          ) : (
            <img
              src={handleSetImagePath("/DPower.webp")}
              alt="DPower"
              className="LandingPage-Footer-item-image"
            />
          )}
        </Grid>
        <Grid
          xs={12}
          sm={6}
          md={4}
          lg={2}
          xl={2}
          className="LandingPage-Footer-grid-item-text"
        >
          <Typography className="LandingPage-Footer-item-text-title">
            Services
          </Typography>
          <Typography className="LandingPage-Footer-item-text">
            Logistic
          </Typography>
          <Typography className="LandingPage-Footer-item-text">
            Tech Solutions
          </Typography>
          <Typography className="LandingPage-Footer-item-text">
            Warehouse
          </Typography>
          <Typography className="LandingPage-Footer-item-text">
            Security
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sm={6}
          md={4}
          lg={2}
          xl={2}
          className="LandingPage-Footer-grid-item-text"
        >
          <Typography className="LandingPage-Footer-item-text-title">
            Contact
          </Typography>
          <Typography className="LandingPage-Footer-item-text">
            +1 (562) 352-4243
          </Typography>

          <Typography
            className="LandingPage-Footer-item-text"
            onClick={() => handleMailTo("contact@dray-power.com")}
          >
            contact@dray-power.com
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          className="LandingPage-Footer-grid-item-text"
        >
          <Typography className="LandingPage-Footer-item-text-title">
            Social
          </Typography>
          <Grid container>
            <Grid xs={3} sm={3} md={3} lg={3} xl={3}>
              <img
                src={handleSetImagePath("/FacebookLogo.webp")}
                alt="Facebook Logo"
                className="LandingPage-Footer-social-media-image"
                onClick={() =>
                  handleOpenNewTab(
                    "https://www.facebook.com/draypowerx"
                  )
                }
              />
            </Grid>
            <Grid xs={3} sm={3} md={3} lg={3} xl={3}>
              {" "}
              <img
                src={handleSetImagePath("/Instagram.webp")}
                alt="Facebook Logo"
                className="LandingPage-Footer-social-media-image"
                onClick={() =>
                  handleOpenNewTab("https://www.instagram.com/draypowerx/")
                }
              />
            </Grid>
            {/* <Grid xs={3} sm={3} md={3} lg={3} xl={3}>
              {" "}
              <img
                src={handleSetImagePath("/Twitter.webp")}
                alt="Facebook Logo"
                className="LandingPage-Footer-social-media-image"
                onClick={() => handleOpenNewTab("https://x.com/snecorp")}
              />
            </Grid> */}
            <Grid xs={3} sm={3} md={3} lg={3} xl={3}>
              {" "}
              <img
                src={handleSetImagePath("/LinkedIn.webp")}
                alt="Facebook Logo"
                className="LandingPage-Footer-social-media-image"
                onClick={() =>
                  handleOpenNewTab(
                    "https://www.linkedin.com/company/draypower/"
                  )
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider className="LandingPage-Footer-divider"></Divider>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className="LandingPage-Footer-legal-text">
            © 2024 DrayPower Corporation. All rights reserved.
          </Typography>
        </Grid>
      </Grid>
    </section>
  );
};

export default Footer;
