// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .LandingPage-FourthPartOfPage-container {
    min-height: calc(80vh);

    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;
}

/* body .LandingPage-FourthPartOfPage- */

body .LandingPage-FourthPartOfPage-grid-item-text {
    justify-content: center;
    align-items: center;
}

body .LandingPage-FourthPartOfPage-grid-general-container {
    height: 100%;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.602);
    padding: 1% 10% 1% 10%;
}

body .LandingPage-FourthPartOfPage-title {
    font-size: 300%;
    color: #22224F;
    font-weight: 900;
}

body .LandingPage-FourthPartOfPage-description {
    font-size: 150%;
    color: #22224F;

}

body .LandingPage-FourthPartOfPage-item-image {
    max-height: 100%;
    max-width: 70%;
}

body .LandingPage-FourthPartOfPage-grid-item-image {
    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/containers/FourthPartOfPage/FourthPartOfPage.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;;IAEtB,4BAA4B;IAC5B,oBAAoB;IACpB,2BAA2B;AAC/B;;AAEA,wCAAwC;;AAExC;IACI,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,4CAA4C;IAC5C,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,cAAc;;AAElB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":["body .LandingPage-FourthPartOfPage-container {\n    min-height: calc(80vh);\n\n    background-repeat: no-repeat;\n    background-size: 50%;\n    background-position: center;\n}\n\n/* body .LandingPage-FourthPartOfPage- */\n\nbody .LandingPage-FourthPartOfPage-grid-item-text {\n    justify-content: center;\n    align-items: center;\n}\n\nbody .LandingPage-FourthPartOfPage-grid-general-container {\n    height: 100%;\n    align-items: center;\n    background-color: rgba(255, 255, 255, 0.602);\n    padding: 1% 10% 1% 10%;\n}\n\nbody .LandingPage-FourthPartOfPage-title {\n    font-size: 300%;\n    color: #22224F;\n    font-weight: 900;\n}\n\nbody .LandingPage-FourthPartOfPage-description {\n    font-size: 150%;\n    color: #22224F;\n\n}\n\nbody .LandingPage-FourthPartOfPage-item-image {\n    max-height: 100%;\n    max-width: 70%;\n}\n\nbody .LandingPage-FourthPartOfPage-grid-item-image {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
