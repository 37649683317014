import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Paper, Typography, Button } from "@mui/material";

//IMAGES----------------------------------------------------------------

//STYLES----------------------------------------------------------------
import "./FirstPartOfPage.css";
const FirstPartOfPage = () => {
  function handleSetImagePath(imagePath) {
    return process.env.REACT_APP_GET_IMAGES + "FirstPartOfPage" + imagePath;
  }

  return (
    <section className="LandingPage-FirstPartOfPage-container">
      <Grid
        container
        spacing={2}
        className="LandingPage-FirstPartOfPage-grid-container"
      >
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={7}
          xl={7}
          className="LandingPage-FirstPartOfPage-grid-text-discover"
        >
          <Typography
            variant="h3"
            className="LandingPage-FirstPartOfPage-grid-text-discover-title"
          >
            Yard Management System
          </Typography>
          <Typography
            variant="h6"
            className="LandingPage-FirstPartOfPage-grid-text-discover-description1"
          >
            Advanced Transportation, Smart Solutions
          </Typography>
          <Typography
            variant="h4"
            className="LandingPage-FirstPartOfPage-grid-text-discover-description2"
          >
            Discover innovation in motion
          </Typography>
          <section className="LandingPage-FirstPartOfPage-grid-text-discover-button-container">
            <Button
              variant="contained"
              className="LandingPage-FirstPartOfPage-grid-text-discover-button"
              onClick={() => {
                window.location.href =
                  "https://outlook.office365.com/book/DrayPowerYMS1@SNECORP.COM/";
              }}
            >
              Schedule a Demo Today
            </Button>
          </section>
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={5}
          xl={5}
          className="LandingPage-FirstPartOfPage-grid-laptop-circle-grid-container"
        >
          <Paper
            elevation={3}
            className="LandingPage-FirstPartOfPage-grid-laptop-circle"
          >
            <img
              loading="lazy"
              src={handleSetImagePath("/LaptopBackground.webp")}
              alt="Laptop"
              className="LandingPage-FirstPartOfPage-grid-laptop-circle-img"
            />
          </Paper>
        </Grid>

        <Grid
          container
          className="LandingPage-FirstPartOfPage-grid-cards-internal-container"
        >
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={3}
            xl={3}
            className="LandingPage-FirstPartOfPage-card-grid"
          >
            <Paper
              elevation={3}
              className="LandingPage-FirstPartOfPage-paper-card"
            >
              <section className="LandingPage-FirstPartOfPage-card-general-margin">
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="LandingPage-FirstPartOfPage-card-internal-margin"
                >
                  <img
                    src={handleSetImagePath("/Tech.webp")}
                    alt="Community"
                    className="LandingPage-FirstPartOfPage-card-internal-margin-img"
                  />
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="LandingPage-FirstPartOfPage-card-internal-margin"
                >
                  <Typography className="LandingPage-FirstPartOfPage-card-internal-text-title">
                    Tech-Driven Transportation Solutions
                  </Typography>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="LandingPage-FirstPartOfPage-card-internal-margin"
                >
                  <Typography className="LandingPage-FirstPartOfPage-card-internal-text-description">
                    Our advanced technology solutions optimize transportation
                    efficiency, reducing downtime and increasing productivity.
                    Harness the power of innovation to stay ahead in the
                    logistics industry.
                  </Typography>
                </Grid>
              </section>
            </Paper>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={3}
            xl={3}
            className="LandingPage-FirstPartOfPage-card-grid"
          >
            <Paper
              elevation={3}
              className="LandingPage-FirstPartOfPage-paper-card"
            >
              <section className="LandingPage-FirstPartOfPage-card-general-margin">
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="LandingPage-FirstPartOfPage-card-internal-margin"
                >
                  <img
                    src={handleSetImagePath("/Community.webp")}
                    alt="Community"
                    className="LandingPage-FirstPartOfPage-card-internal-margin-img"
                  />
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="LandingPage-FirstPartOfPage-card-internal-margin"
                >
                  <Typography className="LandingPage-FirstPartOfPage-card-internal-text-title">
                    A community that shares resources
                  </Typography>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="LandingPage-FirstPartOfPage-card-internal-margin"
                >
                  <Typography className="LandingPage-FirstPartOfPage-card-internal-text-description">
                    Join a network of like-minded professionals dedicated to
                    improving logistics operations. Share insights, resources,
                    and best practices to foster a collaborative environment for
                    growth and success.
                  </Typography>
                </Grid>
              </section>
            </Paper>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={3}
            xl={3}
            className="LandingPage-FirstPartOfPage-card-grid"
          >
            <Paper
              elevation={3}
              className="LandingPage-FirstPartOfPage-paper-card"
            >
              <section className="LandingPage-FirstPartOfPage-card-general-margin">
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="LandingPage-FirstPartOfPage-card-internal-margin"
                >
                  <img
                    src={handleSetImagePath("/RealTime.webp")}
                    alt="Community"
                    className="LandingPage-FirstPartOfPage-card-internal-margin-img"
                  />
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="LandingPage-FirstPartOfPage-card-internal-margin"
                >
                  <Typography className="LandingPage-FirstPartOfPage-card-internal-text-title">
                    Real-Time Container Availability
                  </Typography>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="LandingPage-FirstPartOfPage-card-internal-margin"
                >
                  <Typography className="LandingPage-FirstPartOfPage-card-internal-text-description">
                    Access up-to-the-minute information on container
                    availability to make informed decisions quickly. Our system
                    ensures you have the latest data at your fingertips,
                    enhancing operational efficiency.
                  </Typography>
                </Grid>
              </section>
            </Paper>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={3}
            xl={3}
            className="LandingPage-FirstPartOfPage-card-grid"
          >
            <Paper
              elevation={3}
              className="LandingPage-FirstPartOfPage-paper-card"
            >
              <section className="LandingPage-FirstPartOfPage-card-general-margin">
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="LandingPage-FirstPartOfPage-card-internal-margin"
                >
                  <img
                    src={handleSetImagePath("/Payment.webp")}
                    alt="Community"
                    className="LandingPage-FirstPartOfPage-card-internal-margin-img"
                  />
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="LandingPage-FirstPartOfPage-card-internal-margin"
                >
                  <Typography className="LandingPage-FirstPartOfPage-card-internal-text-title">
                    Flexible Pricing and Payment Plans
                  </Typography>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="LandingPage-FirstPartOfPage-card-internal-margin"
                >
                  <Typography className="LandingPage-FirstPartOfPage-card-internal-text-description">
                    We offer competitive pricing and adjustable payment plans to
                    fit your budget and business needs. Our transparent and
                    flexible approach ensures you get the best value without
                    compromising on quality.
                  </Typography>
                </Grid>
              </section>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};

export default FirstPartOfPage;
