import { Button } from "@mui/material";
import {
  Facebook,
  Instagram,
  WhatsApp,
  LinkedIn,
  Twitter,
  Email,
  Phone,
  EditCalendar,
} from "@mui/icons-material";
import "./SocialMedia.css";

const SocialMedia = () => {
  function handleOpenNewTab(url) {
    window.open(url, "_blank");
  }

  function handleMailTo(email) {
    window.location.href = `mailto:${email}`;
  }
  function handlePhoneCall(phoneNumber) {
    window.location.href = `tel:${phoneNumber}`;
  }

  return (
    <section className="LandingPage-SocialMedia-rightPosition">
      <Button
        variant="contained"
        className="LandingPage-SocialMedia-button facebook-color"
        onClick={() => handleOpenNewTab("https://www.facebook.com/draypowerx")}
      >
        <Facebook /> <span>Facebook</span>
      </Button>
      <Button
        variant="contained"
        className="LandingPage-SocialMedia-button instagram-color"
        onClick={() =>
          handleOpenNewTab("https://www.instagram.com/draypowerx/")
        }
      >
        <Instagram /> <span>Instagram</span>
      </Button>
      {/* <Button
        variant="contained"
        className="LandingPage-SocialMedia-button whatsapp-color"
        onClick={() => handleOpenNewTab("https://wa.me/14242671155")}
      >
        <WhatsApp /> <span>WhatsApp</span>
      </Button> */}
      <Button
        variant="contained"
        className="LandingPage-SocialMedia-button linkedin-color"
        onClick={() =>
          handleOpenNewTab("https://www.linkedin.com/company/draypower/")
        }
      >
        <LinkedIn /> <span>LinkedIn</span>
      </Button>{" "}
      {/* <Button
        variant="contained"
        className="LandingPage-SocialMedia-button twitter-color"
        onClick={() => handleOpenNewTab("https://x.com/snecorp")}
      >
        <Twitter /> <span>Twitter</span>
      </Button> */}
      <Button
        variant="contained"
        className="LandingPage-SocialMedia-button email-color"
        onClick={() => handleMailTo("contact@dray-power.com")}
      >
        <Email /> <span>Email</span>
      </Button>{" "}
      <Button
        variant="contained"
        className="LandingPage-SocialMedia-button calendar-color"
        onClick={() =>
          handleOpenNewTab(
            "https://outlook.office365.com/book/DrayPowerYMS1@SNECORP.COM/"
          )
        }
      >
        <EditCalendar /> <span>Demo</span>
      </Button>
      {/* <Button
        variant="contained"
        className="LandingPage-SocialMedia-button phone-color"
        onClick={() => handlePhoneCall("+15623524243 ")} // Reemplaza +1234567890 con el número de teléfono real
      >
        <Phone /> <span>Call</span>
      </Button> */}
    </section>
  );
};

export default SocialMedia;
